/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "leaflet/dist/leaflet.css";
import { FaWindowClose } from "react-icons/fa";
import { useForm } from "react-hook-form";
import moment from "moment";

import Select from "react-select";
import styled from "styled-components";
import "react-pro-sidebar/dist/css/styles.css";
import L from "leaflet";
import "leaflet.heat";
import scotlandMap from "../constants/mapscot.json";
// import scotlandData from './scotlandData.json';
import { createActivity, deleteActivity, editAcitivity, getActivities, getSchools, getUser, userLogIn } from "../lib/api";
import { Navigate, useNavigate } from "react-router-dom";
import drop from '../assets/drop-bg.png';

const Styles = styled.div`
 background: #D6E8EB;
 height: 100vh;
 padding: 20px;
 justify-content: center;
 align-items: center; 
 display: flex;

 h1 {
   border-bottom: 1px solid white;
   color: #13599A;
   font-family: 'Avenir', sans-serif;   font-size: 20px;
   font-weight: 600;
   line-height: 24px;
   padding: 10px;
   text-align: center;
 }

 form {
   background: #fff;
   border: 1px solid #dedede;
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin: 0 auto;
   width: 70vw;
   max-width: 500px;
   padding: 30px 50px;
 }

 input {
   border: 1px solid #d9d9d9;
   border-radius: 4px;
   box-sizing: border-box;
   padding: 10px;
   width: 100%;
 }

 select {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
}

 label {
   color: #13599A;
   display: block;
   font-family: 'Avenir', sans-serif;   font-size: 14px;
   font-weight: 500;
   margin-bottom: 5px;
 }

 .error {
   color: red;
   font-family: 'Avenir', sans-serif;   font-size: 12px;
   height: 30px;
 }

 .submitButton {
   background-color: #1463AE;
   color: white;
   font-family: 'Avenir', sans-serif;   font-size: 14px;
   margin: 20px 0px;
`;

export const Button = styled.button`
  width: 49%;
  height: 50px;
  border-radius: 5px;
  font-weight: bold;
  border: 0;
  cursor: pointer;
`;

export const LogInButton = styled(Button)`
background-color: #1463AE;
color: white;
font-family: 'Avenir', sans-serif;   font-size: 14px;
margin: 20px 0px;
width: 100%;
height: 40px;
  &:hover {
    color: #ffffff;
    background: #259856;
  }
  &:disabled {
    background: grey;
    color: #CCC !important;
    background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3));
    color: -internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
    border-color: -internal-light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3));
    cursor: disabled;
  }
`;

export default function Map({
  schoolsEngaged = true,
  markersToggled,
  primaryToggled,
  secondaryToggled,
  formToggled,
  handleToggleForm,
  setNewSchoolData,
  newSchoolData,
}) {
  const mapContainerRef = useRef(null);
  let navigate = useNavigate();
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [schoolType, setSchoolType] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState({ value: null });
  const [schools, setSchools] = useState([]);
  const [pupils, setPupils] = useState(null);
  const [description, setDescription] = useState(null);
  const [schoolClass, setSchoolClass] = useState(null);
  const [schoolSelected, setSchoolSelected] = useState(null);
  const [activityType, setActivityType] = useState(null);
  const [activityTypes, setActivityTypes] = useState([
    { value: "Online Workshop", label: "Online Workshop" },
    { value: "In-school Visit", label: "In-school Visit" },
    { value: "Other", label: "Other" },
  ]);
  const [searchText, setSearchText] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [activities, setActivities] = useState([]);
  const [schoolSearchText, setSchoolSearchText] = useState(null);
  const [activityTypeOther, setActivityTypeOther] = useState(null);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);

  const { register, handleSubmit } = useForm();
  const lat = 56.7;
  const lng = -4.4609375;
  const zoom = 6.5;
  const authState = localStorage.getItem('authState')
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => { }, [schoolSearchText]);
  useEffect(() => {
    setSelectedSchool({ value: null });
    fetchSchools();
  }, [schoolType]);

  useEffect(() => {
    const container = L.DomUtil.get(mapContainerRef.current);
    if (container != null) {
      container._leaflet_id = null;
    }
    if (container) {
      const mapView = new L.Map(mapContainerRef.current, {
        zoomSnap: 0.25,
        zoomDelta: 0.25,
      });
      mapView.createPane("markers2");

      const newAddressPoints = [];
      const schoolData = schoolsEngaged
        ? schools.filter((school) =>
          activities.find((activity) => activity.SchoolId === school.id)
        )
        : schools;
      const filteredData = schoolData?.filter((school) => {
        if (primaryToggled && secondaryToggled) {
          return (
            school.SchoolType === "Primary" || school.SchoolType === "Secondary"
          );
        } else if (primaryToggled) {
          return school.SchoolType === "Primary";
        } else if (secondaryToggled) {
          return school.SchoolType === "Secondary";
        }
      });

      filteredData.map((school) => {
        const { Longitude, Latitude, SchoolType, SchoolName, PupilRoll } =
          school;
        const schoolActivities = activities.filter(
          (curActivity) => curActivity.SchoolId === school.id
        );

        newAddressPoints.push([
          Latitude,
          Longitude,
          "1",
          SchoolType,
          SchoolName,
          PupilRoll,
        ]);

        var customPopup = schoolActivities.map((activityInfo) => {
          const formattedDate = moment(activityInfo.createdAt).format("LL");
         const adminButtons = `<span style="padding-left: 15px;">
         <button
           style="
             background-color: #4CAF50;
             border: none;
             color: white;
             padding: 5px 15px;
             text-align: center;
             text-decoration: none;
             display: inline-block;
             font-size: 12px;
             cursor: pointer;
             border-radius: 5px;
         "
           class="edit-${activityInfo.id}"
         >
           Edit
         </button>
         </span><span style="padding-left: 10px;">
           <button
             style="
               background-color: #f44336;
               border: none;
               color: white;
               padding: 5px 15px;
               text-align: center;
               text-decoration: none;
               display: inline-block;
               font-size: 12px;
              cursor: pointer;
              border-radius: 5px;

             "
             class="delete-${activityInfo.id}">
             Delete
           </button>
         </span>`
        
          return `<br /><br /><b>${formattedDate}${authState === 'loggedIn' ? adminButtons : '<span />'}
          </b><br />${activityInfo.className} class of ${PupilRoll}<br/>${activityInfo.description}`;
        });

        var customOptions = {
          maxWidth: "500",
          className: "custom",
        };
        const circleMarker = L.circleMarker([Latitude, Longitude], {
          pane: "markers2",
          radius: 5,
          fillColor: markersToggled ? "#012999" : "rgba(0, 0, 0, 0)",
          // "fillColor": "#012999",
          color: "#012999",
          weight: 1,
          opacity: 0,
        }).addTo(mapView);
  
        circleMarker
          .bindPopup(
            `<div><b><span style="font-size:14px">${SchoolName}<span></b>${customPopup}`,
            customOptions
          )
          .on("popupopen",(e) => {
            if (authState === 'loggedIn') {
              schoolActivities.map((activityInfo) => {
                const edit = document
                .querySelector(`.edit-${activityInfo.id}`)
                edit.addEventListener("click", e => {
                  setSelectedActivity(activityInfo);
                  setDescription(activityInfo.description)
                  const schoolSelected = schoolData.find((curSchool) => curSchool.id === activityInfo.SchoolId);
                  setSchoolSelected(schoolSelected);
                  handleToggleForm(true);
                });
                });
            }
        
          })
          .openPopup();
      });

      // Different terrains available at https://leaflet-extras.github.io/leaflet-providers/preview/
      var CartoDB_VoyagerNoLabels = L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png",
        {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
          subdomains: "abcd",
          minZoom: 7,
          maxZoom: 13,
        }
      );
      mapView.addLayer(CartoDB_VoyagerNoLabels);

      const scotland = scotlandMap;

      mapView.setView(new L.LatLng(lat, lng), zoom);

      // transform geojson coordinates into an array of L.LatLng
      var coordinates = scotland.features[0].geometry.coordinates[0];
      var latLngs = [];
      for (let i = 0; i < coordinates.length; i++) {
        latLngs.push(new L.LatLng(coordinates[i][1], coordinates[i][0]));
      }
      L.Mask = L.Polygon.extend({
        options: {
          stroke: false,
          color: "#D6E8EB",
          fillOpacity: 1,
          clickable: true,
          outerBounds: new L.LatLngBounds([-90, -360], [90, 360]), // potentially fix bug with mask areas visible on zoom out
        },

        initialize: function (latLngs, options) {
          var outerBoundsLatLngs = [
            this.options.outerBounds.getSouthWest(),
            this.options.outerBounds.getNorthWest(),
            this.options.outerBounds.getNorthEast(),
            this.options.outerBounds.getSouthEast(),
          ];
          L.Polygon.prototype.initialize.call(
            this,
            [outerBoundsLatLngs, latLngs],
            options
          );
        },
      });
      L.mask = function (latLngs, options) {
        return new L.Mask(latLngs, options);
      };

      L.mask(latLngs).addTo(mapView);
      const points = newAddressPoints
        ? newAddressPoints.map((p) => {
          return [p[0], p[1]];
        })
        : [];
      L.heatLayer(points, {
        gradient: {
          0.2: "#00FEEF",
          0.4: "#1BCEEB",
          0.6: "#26ADE9",
          0.8: "#388DE9",
          1: "#1265AE",
        },
      }).addTo(mapView);
      return () => mapView.remove();
    }
  }, [
    markersToggled,
    primaryToggled,
    secondaryToggled,
    formToggled,
    schoolsEngaged,
    schools,
    activities,
    authState,
  ]);

  const fetchData = async () => {
    const user = await getUser();
    if (!user) {
      // localStorage.setItem('authState', 'loggedOut');
      // navigate("/login");
    }
    const activities = await getActivities();
    const schools = await getSchools();
    setActivities(activities);
    setSchools(schools || []);
  };
  const fetchSchools = async () => {
    const filters = {
      schoolSearchText,
      schoolType,
    };
    let schoolData = await getSchools(filters);
    const options = [];

    if (schoolData) {
      schoolData.map(({ SchoolName, id }) =>
        options.push({ value: id, label: SchoolName })
      );
    }
    setSchoolOptions(options);
  };
  const resetForm = () => {
    setSchoolOptions([]);
    setSchoolType(null);
    setSelectedSchool({ value: null });
    setPupils("");
    setDescription("");
  };
  const saveActivity = async () => {
    const schoolInfo = schools.find(
      (curSchool) => curSchool.id === selectedSchool.value
    );
    if (selectedActivity) {
      await editAcitivity(
        selectedActivity.id,
        "",
        activityType || selectedActivity.type,
        selectedSchool.Longitude,
        selectedSchool.Latitude,
        "",
        description || selectedActivity.description,
        schoolClass || selectedActivity.className
      ).then(async () => {
        const updatedSchools = await getSchools();
        const updatedActivities = await getActivities();
        setSchools(updatedSchools);
        setActivities(updatedActivities);
      });
    } else {
      await createActivity(
        schoolInfo.id,
        "",
        activityType,
        schoolInfo.Longitude,
        schoolInfo.Latitude,
        "",
        description,
        schoolClass
      ).then(async () => {
        const updatedSchools = await getSchools();
        const updatedActivities = await getActivities();
        setSchools(updatedSchools);
        setActivities(updatedActivities);
      });
    }
   
    handleToggleForm(false);
    resetForm();
  };
  const renderMap = () => (
    <div ref={mapContainerRef} id="map" style={{ height: "100vh" }}></div>
  );

  const onChangeSchool = (e) => {
    setSelectedSchool(e);
  };

  const onChangeSchoolType = (e) => {
    setSelectedSchool({ value: null });
    setSchoolType(e.value);
  };

  const onChangeSchoolClass = (e) => {
    setSchoolClass(e.value);
  };

  const onChangeActivityType = (e) => {
    setActivityType(e.value);
  };

  const MenuList = function MenuList(props) {
    const children = props.children;

    if (!children.length) {
      return <div className="myClassListName">{children}</div>;
    }

    return (
      <div className="myClassListName">
        {children.length &&
          children.map((key, i) => {
            delete key.props.innerProps.onMouseMove; //FIX LAG!!
            delete key.props.innerProps.onMouseOver; //FIX LAG!!

            return (
              <div className="myClassItemName" key={i}>
                {key}
              </div>
            );
          })}
      </div>
    );
  };
  const renderForm = () => {
    const schoolTypes = [
      { value: "Primary", label: "Primary" },
      { value: "Secondary", label: "Secondary" },
    ];
    const primaryClasses = [
      { value: "P1", label: "P1" },
      { value: "P2", label: "P2" },
      { value: "P3", label: "P3" },
      { value: "P4", label: "P4" },
      { value: "P5", label: "P5" },
      { value: "P6", label: "P6" },
      { value: "P7", label: "P7" },
    ];
    const secondaryClasses = [
      { value: "S1", label: "S1" },
      { value: "S2", label: "S2" },
      { value: "S3", label: "S3" },
      { value: "S4", label: "S4" },
      { value: "S5", label: "S5" },
      { value: "S6", label: "S6" },
    ];
    const schoolClasses =
      schoolType === "Primary" || selectedSchool.SchoolType === 'Primary' ? primaryClasses : secondaryClasses;
      if (localStorage.authState === 'loggedOut') {
        return (
          <div style={{ height: "100vh", backgroundColor: "white" }}>
        <Styles>
          <form
            style={{ marginBottom: 50 }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                paddingBottom: 10,
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", flex: 0.5, marginLeft: -10 }}>
                <h1 style={{
                    alignItems: 'center',
                    display: 'flex'
                  }}>
                  <img src={drop} width="30px" />
                  <span style={{ paddingLeft: 20 }}>Log in</span>
                  </h1>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 0.5,
                }}
              >
                <FaWindowClose
                  size={30}
                  color={"#1463AE"}
                  onClick={() => {
                    resetForm();
                    setDescription(null)
                    handleToggleForm(false);
                  }}
                />
              </div>
            </div>
            <br />
            <label>Email</label>
            <input
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <br />
            <label>Password</label>
            <input
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              type="password"
            />
            <br />
            <LogInButton
              disabled={!email || !password}
              onClick={async (e) => {
                e.preventDefault();
                const res = await userLogIn(email, password);
                if (res.ok) {
                  setError();
                  localStorage.setItem('authState', 'loggedIn')
                  handleToggleForm(false);
                  setEmail(null);
                  setPassword(null);
                } else {
                  localStorage.setItem('authState', 'loggedOut')
                  setError('Unable to log in');
                }
              }}
            >
              Log In
            </LogInButton>
          </form>
        </Styles>
      </div>
        )
      }
    return (
      <div style={{ height: "100vh", backgroundColor: "white" }}>
        <Styles>
          <form
            onSubmit={handleSubmit((data) => saveActivity(data))}
            style={{ marginBottom: 50 }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                paddingBottom: 10,
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", flex: 0.5, marginLeft: -10 }}>
                <h1>{selectedActivity ? 'Edit Activity' : 'New Activity'}</h1>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 0.5,
                }}
              >
                <FaWindowClose
                  size={30}
                  color={"#1463AE"}
                  onClick={() => {
                    resetForm();
                    handleToggleForm(false);
                    setSelectedActivity(null);
                    setSelectedSchool({ value: null });
                  }}
                />
              </div>
            </div>
            <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
              <div
                style={{ flex: 0.6, display: "flex", flexDirection: "column" }}
              >
                <label>Activity Type</label>
                <Select
                  defaultValue={selectedActivity ? { value: selectedActivity.type, label: selectedActivity.type } : activityType}
                  onChange={onChangeActivityType}
                  options={activityTypes}
                />
              </div>
              <div
                style={{ flex: 0.1, display: "flex", flexDirection: "column" }}
              />
              <div
                style={{ flex: 0.3, display: "flex", flexDirection: "column" }}
              >
                <label>Class</label>
                <Select
                  placeholder="Select..."
                  defaultValue={selectedActivity && { value: selectedActivity.className, label: selectedActivity.className }}
                  onChange={onChangeSchoolClass}
                  options={schoolClasses}
                />
              </div>
            </div>

            {activityType === "Other" && (
              <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
                <div
                  style={{
                    flex: 0.6,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <br />
                  <label>Other Activity Type</label>
                  <input
                    name="activityTypeOther"
                    value={activityTypeOther}
                    {...register("activityTypeOther")}
                    onChange={(e) => setActivityTypeOther(e.target.value)}
                  />
                </div>
              </div>
            )}
            <br />

            <div style={{ flexDirection: "row", flex: 1, display: "flex" }}>
              <div
                style={{ flex: 0.6, display: "flex", flexDirection: "column" }}
              >
                <label>School Type</label>
                <Select
                  defaultValue={selectedActivity && schoolSelected && { value: schoolSelected.SchoolType, label: schoolSelected.SchoolType }}
                onChange={onChangeSchoolType}
                  options={schoolTypes}
                  isDisabled={selectedActivity}
                />
              </div>
              <div
                style={{ flex: 0.1, display: "flex", flexDirection: "column" }}
              />
              <div
                style={{ flex: 0.3, display: "flex", flexDirection: "column" }}
              >
                <label>Number of Pupils</label>
                <input
                  name="pupils"
                  value={pupils}
                  {...register("pupils", { required: true })}
                  onChange={(e) => setPupils(e.target.value)}
                />
              </div>
            </div>
            <br />

            <label>School Name</label>
            <Select
              placeholder="Search..."
              components={{
                MenuList,
              }}
              defaultValue={selectedActivity && schoolSelected ? { value: selectedActivity.id, label: schoolSelected.SchoolName } : selectedSchool.value}
              onChange={onChangeSchool}
              isDisabled={selectedActivity}
              options={schoolOptions}
              onInputChange={(e) => setSchoolSearchText(e)}
            />
            <br />

            <label>Brief Description of Activity</label>
            <input
              name="description"
              value={description}
              {...register("description", { required: true })}
              onChange={(e) => setDescription(e.target.value)}
            />
            <br />
            <input type="submit" value="Save" className="submitButton" style={{cursor:'pointer'}} />
          </form>
        </Styles>
      </div>
    );
  };
  return formToggled ? renderForm() : renderMap();
}
