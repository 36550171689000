import React from 'react';
import App from './App';
import Login from './pages/login/Login';

import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
// import your route components too
// const authState = localStorage.getItem('authState')

const MainElement = App;

render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<MainElement />} />
      <Route path="/login" element={<Login />} />
      <Route path="/map" element={<App />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);