/* eslint-disable no-console */
const { REACT_APP_API_URL } = process.env;

export async function apiCall(url, options = {}) {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    withCredentials: true,
  };
  Object.assign(options, {
    withCredentials: true,
    credentials: 'include',
  });
  const headers = options.headers
    ? { ...defaultHeaders, ...options.headers }
    : defaultHeaders;
  return fetch(`${REACT_APP_API_URL}${url}`, { ...options, headers });
};

export function filterUrlGenerator(filters) {
  if (!filters) {
    return '';
  }
  let url = '?';
  const keys = Object.keys(filters);
  const parsedKeys = keys
    .filter((key) => filters[key])
    .map((key) => `${key}=${filters[key]}`);
  parsedKeys.forEach((parsedKey, i) => {
    url += `${parsedKey}${i + 1 !== parsedKeys.length ? '&' : ''}`;
  });
  return parsedKeys.length ? url : '';
}
