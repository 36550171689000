import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  useNavigate,
} from "react-router-dom"
import LoginHeader from './LoginHeader';
import { userLogIn } from '../../lib/api';

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #83CADB;
  `;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  font-size: 18pt;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 0px;
  padding: 0px;
  text-indent: 10px;
  `;

export const LogInContainer = styled.div`
  margin-top: 25vh;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  max-height: 500px;
  background: #1265AE;
  padding: 25px;
  text-align: center;
  border-radius: 15px;
`;

export const LogInInnerContainer = styled.div`
  width: 450px;
  margin-right: auto;
  margin-left: auto;
`;

export const ButtonGroup = styled.div`
  height: 50px;
  width: 100%;
`;

export const Button = styled.button`
  width: 49%;
  height: 50px;
  border-radius: 5px;
  font-weight: bold;
  border: 0;
  cursor: pointer;
`;

export const LogInButton = styled(Button)`
  background: #1A6B3D;
  color: #EEEEEE;
  float: right;
  &:hover {
    color: #ffffff;
    background: #259856;
  }
  &:disabled {
    background: grey;
    color: #CCC !important;
    background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3));
    color: -internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
    border-color: -internal-light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3));
    cursor: disabled;
  }
`;

export const ForgotButton = styled(Button)`
  float: left;
`;

export const ErrorMsg = styled.div`
  color: white;
  font-size: 16pt;
`;

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    setError('');
  }, [password, email])

  return (
    <Wrapper>
      <LogInContainer>
        <LogInInnerContainer>
          <LoginHeader />
          <Input
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <Input
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            type="password"
          />
          <ErrorMsg>{error}</ErrorMsg>
          <ButtonGroup>
            <ForgotButton>Forgot password</ForgotButton>
            <LogInButton
              disabled={!email || !password}
              onClick={async () => {
                const res = await userLogIn(email, password);
                if (res.ok) {
                  setError();
                  localStorage.setItem('authState', 'loggedIn')
                  navigate('/map');
                } else {
                  localStorage.setItem('authState', 'loggedOut')
                  setError('Unable to log in');
                }
              }}
            >
              Log In
            </LogInButton>
          </ButtonGroup>
        </LogInInnerContainer>
      </LogInContainer>
    </Wrapper>
  )
}

export default Login;