import React from 'react';
import styled from '@emotion/styled';
import drop from '../../assets/drop-bg.png';

export const Wrapper = styled.div`
  height: 100px;
  vertical-align: text-top;
`;
export const Text = styled.span`
  font-weight: bold;
  font-size: 18pt;
  color: white;
`;
export const Image = styled.img`
  height: 60px;
  padding: 10px;
  margin-bottom: -30px;
`;

const LoginHeader = () => {
  return (
    <Wrapper>
      <Text>Login to the Education Tracker</Text>
      <Image src={drop} />
    </Wrapper>
  )
}

export default LoginHeader;