import React from 'react';
import Layout from './pages/main/Layout';
import './styles/App.scss';

function App() {
  return (
    <Layout />
  );
}
export default App;
