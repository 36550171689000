import { apiCall, filterUrlGenerator } from "./helpers";


// Login
export async function userLogIn(email, password) {
  localStorage.removeItem('user');
  const res = await apiCall('/login', {
    method: 'POST',
    body: JSON.stringify({ email: email.toLowerCase(), password }),
  });
  return res;
}

// Logout
export async function userLogOut() {
  const res = await apiCall('/logout', {
    method: 'POST',
  });
  localStorage.setItem('authState', 'loggedOut');
  localStorage.removeItem('user');
  return res;
}

// Get user
export async function getUser(filters) {
  const res = await apiCall('/user');
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem getting user: ${res.status}`);
  return false;
}

// Get activities
export async function getActivities(filters) {
    const res = await apiCall(`/activity/search${filterUrlGenerator(filters)}`);
    if (res.ok) {
      const body = await res.json();
      return body;
    }
    console.error(`Problem getting activities: ${res.status}`);
    return false;
  }

// Create activity
  export async function createActivity(SchoolId, name, type, longitude, latitude, eventLocation, description, className) {
    const res = await apiCall('/activity', {
      method: 'POST',
      body: JSON.stringify({ SchoolId, name, type, longitude, latitude, eventLocation, description, className }),
    });
    console.log(res)
    if (res.ok) {
      return res;
    }
    console.error(`Problem creating new activity: ${res.status}`);
    return false;
  }
  

// Edit activity
  export async function editAcitivity(id, name, type, longitude, latitude, eventLocation, description, className) {
    const res = await apiCall('/activity', {
      method: 'PUT',
      body: JSON.stringify({ id, name, type, longitude, latitude, eventLocation, description, className }),
    });
    if (res.ok) {
      return res;
    }
    console.error(`Problem editing an activity: ${res.status}`);
    return false;
  }

// Delete activity
  export async function deleteActivity(id) {
    const res = await apiCall(`/activity/${id}`, {
      method: 'DELETE',
    });
    if (res.ok) {
      return res;
    }
    console.error(`Problem deleting activity: ${res.status}`);
    return false;
  }

  // Get schools by search
export async function getSchools(filters) {
    const res = await apiCall(`/school/search${filterUrlGenerator(filters)}`);
    console.log(res)
    if (res.ok) {
      const body = await res.json();
      return body;
    }
    console.error(`Problem getting schools: ${res.status}`);
    return false;
  }
