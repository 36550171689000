import React, { useState } from 'react';
import Aside from '../../components/Aside';
import Main from './Main';

function Layout({ setLocale }) {
  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [markersToggled, setMarkersToggled] = useState(true);
  const [primaryToggled, setPrimaryToggled] = useState(true);
  const [secondaryToggled, setSecondaryToggled] = useState(true);
  const [formToggled, setFormToggled] = useState(false);
  const [newSchoolData, setNewSchoolData] = useState([]);
  const [schoolsEngaged, setSchoolsEngaged] = useState(true);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
    setLocale(checked ? 'ar' : 'en');
  };
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const handleToggleMarkers = (value) => {
    setMarkersToggled(!markersToggled);
  };

  const handleTogglePrimary = (value) => {
    setPrimaryToggled(!primaryToggled);
  };

  const handleToggleSecondary = (value) => {
    setSecondaryToggled(!secondaryToggled);
  };

  const handleToggleForm = (value) => {
    setFormToggled(value);
  };

  const handleSchoolEngaged = (value) => {
    setSchoolsEngaged(value);
  };

  return (
    <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
      <Aside
        newSchoolData={newSchoolData}
        image={image}
        collapsed={collapsed}
        rtl={rtl}
        toggled={toggled}
        markersToggled={markersToggled}
        primaryToggled={primaryToggled}
        secondaryToggled={secondaryToggled}
        setNewSchoolData={setNewSchoolData}
        formToggled={formToggled}
        handleToggleSidebar={handleToggleSidebar}
        handleToggleMarkers={handleToggleMarkers}
        handleTogglePrimary={handleTogglePrimary}
        handleToggleSecondary={handleToggleSecondary}
        handleToggleForm={handleToggleForm}
        handleSchoolEngaged={handleSchoolEngaged}
        schoolsEngaged={schoolsEngaged}
      />
      <Main
        schoolsEngaged={schoolsEngaged}
        newSchoolData={newSchoolData}
        setNewSchoolData={setNewSchoolData}
        image={image}
        toggled={toggled}
        collapsed={collapsed}
        rtl={rtl}
        handleToggleForm={handleToggleForm}
        markersToggled={markersToggled}
        primaryToggled={primaryToggled}
        secondaryToggled={secondaryToggled}
        formToggled={formToggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        handleRtlChange={handleRtlChange}
        handleImageChange={handleImageChange}
      />
    </div>
  );
}

export default Layout;
