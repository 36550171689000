import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaChild, FaSchool, FaBuilding, FaPlusCircle, FaReply, FaSignOutAlt, FaSignInAlt } from 'react-icons/fa';
import scotlandData from '../constants/scotlandData.json';
import drop from '../assets/drop-bg.png';
import { userLogOut } from '../lib/api';
import { useNavigate } from 'react-router-dom';

const newAddressPoints = [];
let pupils = 0;
let primarySchools = 0;
let secondarySchools = 0;
scotlandData.schools.map(school => {
  const { Longitude, Latitude, SchoolType, WebsiteAdd, Phone, SchoolName, PupilRoll } = school.attributes;
  newAddressPoints.push([Latitude, Longitude, "1", SchoolType, WebsiteAdd, Phone, SchoolName, PupilRoll])
  pupils += PupilRoll;
  if (SchoolType === 'Secondary') {
    secondarySchools += 1;
  } else if (SchoolType === 'Primary') {
    primarySchools += 1;
  }
})
const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Aside = ({ schoolsEngaged, handleSchoolEngaged, handleToggleForm, collapsed, rtl, toggled, handleToggleSidebar, handleToggleMarkers, handleToggleSecondary, handleTogglePrimary, markersToggled, primaryToggled, secondaryToggled, newSchoolData }) => {
  const authState = localStorage.getItem('authState')
  let navigate = useNavigate();

console.log('authState')
console.log(authState)
  return (
    <ProSidebar
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            position: 'absolute',
            float: 'right',
            right: 0,
            paddingRight: 20,
            paddingTop: 5,
          }}
        >
          <img src={drop} width="30px" />
        </div>
        <div
          style={{
            flexDirection: 'row',
            flex: 1
          }}
        >
          <div
            style={{
              padding: '24px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 14,
              letterSpacing: '1px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flex: 0.5,
              color: 'white'

            }}
          >
            Education Tracker
          </div>

        </div>

      </SidebarHeader>

      <SidebarContent>
        {authState === 'loggedIn'
           ? (
            <Menu iconShape="circle">
          <MenuItem
            icon={<FaPlusCircle size={26} />}
            onClick={() => handleToggleForm(true)}
          >
            New Activity
          </MenuItem>
        </Menu>
           ) :
           (
            <Menu iconShape="circle">
            <MenuItem
              icon={<FaSignInAlt size={26} />}
              onClick={() => handleToggleForm(true)}
            >
              Log in
            </MenuItem>
          </Menu>
           )
        }
        <div style={{ backgroundColor: '#1364AE', height: 35, paddingTop: 15 }}>
          <span style={{ color: 'white', paddingLeft: 20 }}>Show</span>
        </div>
        <div style={{ backgroundColor: '#83CADB', color: '#082744', width: '100%', }}>
          <div style={{ paddingLeft: 20 }}>
          </div>
          <div style={{ paddingLeft: 20 }}>
            <div>
              <br />
              <label class="b-contain">
                <input type="checkbox" name="markers" checked={markersToggled} onClick={() => handleToggleMarkers()} />

                <span style={{ paddingLeft: 10, paddingBottom: 10, color: 'black' }}>Markers</span>
                <div class="b-input"></div>

              </label>
              <br />
            </div>
            <div style={{ flexDirection: 'row' }}>

              <label class="b-contain">
                <input type="checkbox" name="primary" checked={primaryToggled} onClick={() => handleTogglePrimary()} />

                <span style={{ paddingLeft: 10, paddingBottom: 5 }}>Primary Schools</span>
                <div class="b-input"></div>

              </label>
            </div>

            <br />

            <div>
              <label class="b-contain">
                <input type="checkbox" name="secondary" checked={secondaryToggled} onClick={() => handleToggleSecondary()} />

                <span style={{ paddingLeft: 10, paddingBottom: 5, }}>Secondary Schools</span>
                <div class="b-input"></div>

              </label>
              <br />

            </div>

          </div>
        </div>

        <div style={{ backgroundColor: '#1364AE', height: 35, paddingTop: 15 }}>
          <span style={{ color: 'white', paddingLeft: 20 }}>View</span>
        </div>
        <div style={{ backgroundColor: '#83CADB', color: '#082744', width: '100%', }}>
          <div style={{ paddingLeft: 20 }}>
          </div>
          <div style={{ paddingLeft: 20 }}>
            <div>
              <br />
              <label class="b-contain">
                <input type="radio" name="radio1" checked={schoolsEngaged} onClick={() => handleSchoolEngaged(true)} />

                <span style={{ paddingLeft: 10, paddingBottom: 10 }}>Engaged Schools</span>
                <div class="b-input"></div>
              </label>
              <br />
              <label class="b-contain">
                <input type="radio" name="radio2" checked={!schoolsEngaged} onClick={() => handleSchoolEngaged(false)} />
                <span style={{ paddingLeft: 10, paddingBottom: 10 }}>Unengaged Schools</span>
                <div class="b-input"></div>
              </label>
            </div>
            <br />

          </div>
        </div>

      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'left' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '50px 24px',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <span style={{ paddingBottom: 5, color: 'white' }}><span style={{ paddingRight: 10, }}><FaChild /></span>Pupils: <span style={{ textAlign: 'right', flexDirection: 'flex-end' }}><b>{numberWithCommas(pupils)}</b></span></span>
          <br /> <span style={{ paddingBottom: 5, color: 'white' }}><span style={{ paddingRight: 10 }}><FaSchool /></span>Primary Schools: <b>{numberWithCommas(primarySchools)}</b></span>
          <br /> <span style={{ paddingBottom: 5, color: 'white' }}><span style={{ paddingRight: 10 }}><FaBuilding /></span>Secondary Schools: <b>{numberWithCommas(secondarySchools)}</b></span>
        </div>
        {authState === 'loggedIn'
           && (
            <Menu iconShape="circle">
          <MenuItem
            icon={<FaSignOutAlt size={26} />}
            onClick={async () => {
              await userLogOut();
              navigate('/map')
            }}
          >
            Log out
          </MenuItem>
        </Menu>
           )
        }
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
