import React from 'react';
import { FaBars } from 'react-icons/fa';
import Map from '../../components/Map';

const Main = ({
  handleToggleSidebar,
  setNewSchoolData,
  markersToggled,
  primaryToggled,
  secondaryToggled,
  formToggled,
  handleToggleForm,
  newSchoolData,
  schoolsEngaged,
}) => {
  return (
    <main>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <Map
        schoolsEngaged={schoolsEngaged}
        newSchoolData={newSchoolData}
        setNewSchoolData={setNewSchoolData}
        handleToggleForm={handleToggleForm}
        markersToggled={markersToggled}
        primaryToggled={primaryToggled}
        secondaryToggled={secondaryToggled}
        formToggled={formToggled}
      />
    </main>
  );
};

export default Main;
